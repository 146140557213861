import { Fragment, useState, useCallBack } from "react";
import { productStats } from "../Network";
import ContentLoader, { Facebook } from "react-content-loader";
import moment from "moment";
function CardV2(props) {
  var product = props.item;
  var currentPromo = props.activePromo;
  const [color, setSelectedColor] = useState(null);
  const [isAdded, setAdded] = useState(props.existing);
  var endDate = moment("2024-02-12-16:00", "YYYY-MM-DD HH:mm");
  let startDate = moment(new Date());
  var unAvailable =
    "object-cover w-full -mt-3 h-96 opacity-100 hover:opacity-50 ease-in-out duration-300 opacity-0";
  var available =
    "object-cover rounded-lg lg:object-cover md:object-cover  w-full -mt-3 h-60  lg:h-96 opacity-100 hover:opacity-50 ease-in-out duration-300";

  async function recordProductStats(e, type) {
    try {
      e.embedding = null;
      const data = {
        storeOwner: "TheFlowerLuxeCebu",
        cType: "cartBehavior",
        cName: "website",
        data: e,
        date: new Date(),
        action: type,
      };
      const response = await productStats.put("", data);
      return response;
    } catch (error) {}
  }
  const validateItem = (e) => {
    try {
      let existingCart = localStorage.getItem("UserCart");
      var cart = JSON.parse(existingCart);
      const found = cart.find((obj) => {
        return obj._id === e._id;
      });

      let status = found === undefined ? true : false;
      return status;
    } catch (error) {
      return true;
    }
  };
  const Loader = () => (
    <ContentLoader viewBox="0 0 500 280" height={280} width={500} {...props}>
      <rect x="3" y="3" rx="10" ry="10" width="300" height="180" />
      <rect x="6" y="190" rx="0" ry="0" width="292" height="20" />
      <rect x="4" y="215" rx="0" ry="0" width="239" height="20" />
      <rect x="4" y="242" rx="0" ry="0" width="274" height="20" />
    </ContentLoader>
    // <ContentLoader viewBox="0 0 380 70">

    //   <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    //   <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    //   <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    // </ContentLoader>
  );

  function addToCart(e) {
    if (props.isDIsabled) {
      return;
    }
    if (validateItem(e) && e.status) {
      var variantItem = e;
      variantItem.selectedColor = color;
      props.didAdd(variantItem);
      setAdded(true);
      recordProductStats(e, "Added");
    } else {
      setAdded(false);
    }
  }

  function removeToCart(e) {
    props.didRemove(e);
    setAdded(false);
  }

  const validateIfPromo = (e) => {
    try {
      console.log(currentPromo, e);
      let list = currentPromo.filter((item) => {
        return item.category === e;
      });

      return list.length === 0 ? false : true;
    } catch (error) {
      return false;
    }
  };
  const displayRedPrice = () => {
    try {
      if (product.basePrice >= Number(product.price)) {
        return (
          <p
            class={
              product.basePrice >= Number(product.price)
                ? "text-xs  text-red-600 line-through duration-300 ease-in-out "
                : "text-xs font-medium duration-300 ease-in-out group-hover:text-black "
            }
          >
            {new Intl.NumberFormat("en-PH", {
              style: "currency",
              currency: "PHP",
            }).format(product.basePrice)}
          </p>
        );
      } else {
        return null;
      }
    } catch (error) {}
  };

  let card2 = () => {
    return (
      <a href={`/details/${product._id}`} target="_blank">
        <div className="mb-10">
          {product.status ? null : (
            <div class="relative flex justify-center">
              <strong class="relative h-6 rounded-full bg-red-900 px-4 text-xs font-extralight uppercase leading-6 text-white">
                {" "}
                Out of stock{" "}
              </strong>
            </div>
          )}

          <section className={available + ` hover:shadow-lg`}>
            {/* <a onClick={() => addToCart(product)} class="group block"> */}
            <div>
              <img
                alt={product.title}
                src={product.imgUrl}
                class={available}
                style={{
                  opacity: product.status ? 1 : 0.1,
                }}
              />
            </div>
            {/* </a> */}

            {/* <div style={{top:10,right:20,color:'red'}} className="fixed z-10">Quickview</div> */}
          </section>
          <p class="mb-2 mt-4 text-xs font-medium capitalize text-black/90">
            {product.title}{" "}
          </p>
          <textarea
            class="
        form-control
        m-0
        block
        w-full
        rounded

        border
        border-solid
        border-gray-100
        bg-white bg-clip-padding
        px-3 py-1.5 text-xs
        font-normal
        text-gray-500
        transition
        ease-in-out
        hover:shadow-lg
        focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none
      "
            id="exampleFormControlTextarea1"
            rows="1"
            placeholder="Your message"
            value={"Florals: " + product.subtitle}
          ></textarea>

          <div class="mt-4 flex items-center justify-between font-bold">
            <div>
              <p
                class={
                  Number(product.basePrice) > Number(product.price)
                    ? `text-xs text-red-600 line-through  duration-300 ease-in-out `
                    : "text-xs font-medium duration-300 ease-in-out group-hover:text-black hidden "
                }
              >
                {new Intl.NumberFormat("en-PH", {
                  style: "currency",
                  currency: "PHP",
                }).format(product.basePrice)}
              </p>
              <p
                class={
                  false
                    ? `text-xs text-red-600 line-through  duration-300 ease-in-out `
                    : "text-xs font-medium duration-300 ease-in-out group-hover:text-black "
                }
              >
                {new Intl.NumberFormat("en-PH", {
                  style: "currency",
                  currency: "PHP",
                }).format(product.price)}
              </p>
            </div>

            {/* or 400 Credit Card */}
            {/* { validateIfPromo(product.type)  ?     <p class="text-lg group-hover:text-black ease-in-out duration-300 ">
              { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(product.price - (product.price * 0.1))}
              </p>: null} */}
            <div className="flex  grid-cols-2">
              <a
                href="javascript:void(0);"
                onClick={() => addToCart(product)}
                class="group block"
              >
                {product.status ? (
                  <div
                    style={{ justifyContent: "center", flexDirection: "row" }}
                  >
                    {/* add to cart */}
                    <img
                      width={20}
                      height={20}
                      onClick={() => addToCart(product)}
                      style={{ margin: 5 }}
                      className=" duration-300 ease-in-out  "
                      alt={product.title}
                      // src="https://uxwing.com/wp-content/themes/uxwing/download/e-commerce-currency-shopping/cart-icon.svg"
                      src={
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRymu6sl4j8YwAhAo8Rz0Owww4-jgw4gmmgQahRi5tyya1xVxAglgaUxpBXlBiEMRNFQM&usqp=CAU"
                      }
                    />
                  </div>
                ) : null}
              </a>
            </div>
          </div>
        </div>
      </a>
    );
  };
  return props.isDisabled ? Loader() : card2();
}

export default CardV2;
