"use client";

import { useState } from "react";

export default function ColorPalette(props) {
  const color2s = [
    { name: " Red", hex: "#E74C3C", code: "E74C3C", status: false },
    { name: " Pink", hex: "#E91E63", code: "E91E63", status: false },
    { name: " Peach", hex: "#F8B195", code: "F8B195", status: false },
    { name: " Yellow", hex: "#F1C40F", code: "F1C40F", status: false },
    { name: " Purple", hex: "#9B59B6", code: "9B59B6", status: false },
    { name: " White", hex: "#ECF0F1", code: "ECF0F1", status: false },
    { name: "none", hex: "", code: "" },
  ];

  const [selectedColor, setSelectedColor] = useState(null);
  const [colors, setColors] = useState(
       props.options === undefined ? color2s : props.options,
  );
  const handleColorClick = (color) => {

    if (color.status && color.hex !== "black")  {
      props.selectedColor(color);
      console.log(color)
      setSelectedColor(color);
      navigator.clipboard.writeText(color.hex);
    } else if(color.hex === "black" || color.hex === "") {
      setSelectedColor(null)
        props.selectedColor(color);
    } else {
      props.disabled();
    }

  };

  const renderColors=()=>{
   let list :[]=  colors.filter((item)=> item.hex !== "").map((color) => (
      <div
        key={color.code}
        onClick={() => handleColorClick(color)}
        className="group cursor-pointer "
      >
        <div
          style={{ opacity: color.status ? 1 : 0.1 }}
          className="relative h-20 w-20 rounded-full justify-center align-middle flex items-center shadow-lg overflow-hidden transition-transform transform hover:scale-105"
        >
          <div
            className={` rounded-full ${selectedColor != null ? selectedColor.hex === color.hex ?' border-4 border-green-600 scale-50 h-20 w-20 ' :'h-16 w-16 ' :'h-16 w-16 '}`  }
            style={{

              backgroundColor: color.hex === "" ? "black" : color.hex,
            }}
          >
            <div className="absolute top-4 left-4 space-y-1">
              <div className="h-4 w-4 rounded-full bg-white/30"></div>
              <div className="h-4 w-4 rounded-full bg-white/20"></div>
            </div>
            {/* <div className="p-4 bg-white lg:hidden block">
              <h3 className="font-medium text-gray-900">{color.name}</h3>
              <p className="text-sm text-gray-500">{color.code}</p>
            </div> */}
          </div>

        </div>
        <div className="p-4 bg-white lg:hidden block">
          <h3 className="font-medium text-gray-900">{color.name}</h3>
          {/* <p className="text-sm text-gray-500">{color.code}</p> */}
        </div>
      </div>
    ))
   list.push(
     <div
       key="black"
       onClick={() => handleColorClick(   { name: "", hex: "black", code: "black", status: true })}
       className="group cursor-pointer  "
     >
       <div

         className="relative h-20 w-20  border-red-400  rounded-full overflow-hidden transition-transform transform hover:scale-105"
       >
         <div
           className="h-20 w-20 justify-center flex items-center"
           style={{
             backgroundColor:'white '
           }}
         >
           <div className="absolute   space-y-1">
             <div className=" w-16 h-16 rounded-full bg-gradient-to-r from-red-500 to-red-700">
               <div className="absolute inset-0 flex items-center justify-center">
                 <div className="w-8 h-1 bg-white rotate-45"></div>
                 <div className="w-8 h-1 bg-white -rotate-45"></div>
               </div>
             </div>
           </div>

         </div>

         {/* <div className="p-4 bg-white">
           <h3 className="font-medium text-gray-900">{color.name}</h3>
           <p className="text-sm text-gray-500">{color.code}</p>
         </div> */}
       </div>

     </div>
   )
   return list
  }
  return (
    <div className="mb-20 mt-10">
      {/* <h2 className="text-sm">Regular price</h2> */}
      <h2 className=" mb-6 text-sm">Pick the perfect rose flower color</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {renderColors()}
      </div>

      {/* {selectedColor && (
        <div className="fixed bottom-4 right-4 z-10">
          <div className="bg-black text-white px-4 py-2 rounded-full text-sm">
            Selected {selectedColor.name}!
          </div>
        </div>
      )} */}
    </div>
  );
}
